import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Row, Col, CardBody, CardImg, Container } from "reactstrap";
import { connect } from "react-redux";
import { selectUserOrganization, selectUserMarketIds, selectUserPermissions, selectUserGraphData } from "src/redux/user/user.selectors";
import profilebgBlue from "src/assets/img/BackgroundImages/ProvisioningPicture_Blue.png";
import profilebgBlack from "src/assets/img/BackgroundImages/ProvisioningPicture_Black.png";
import Typography from "@material-ui/core/Typography";
import SearchButton from "src/components/buttons/search-button-component";
import VehiclePageDialog from "src/components/form-elements/vehiclePage-dialog.component";
import okIcon from "src/assets/img/vehicles/okIcon.png";
import noIcon from "src/assets/img/vehicles/noIcon.png";
import Modal from "@mui/material/Modal";
import { GetVehicleByVinOrLicensePlate } from "src/services/vehicle-pages-http.service";
import { ProvisionVehicle, GetUsersProvisionedToVehicle, ActivateVehicleSim } from "src/services/provisioning-http.service";
import { QueryCEPCustomer } from "src/services/cep-http.service";
import SearchBarProvisioning from "src/components/buttons/search-button-provisioning.component";
import PackageSelect from "./PackageSelect";
import RoleSelect from 'src/pages/driver-page/RoleSelect';
import PuffLoader from "react-spinners/PuffLoader";
import FadeLoader from "react-spinners/FadeLoader";
import { permission } from 'src/auth/permissions';
import DefaultPage from 'src/pages/backgrounds/default-page';
import { GetWhoIsConnectedData } from 'src/services/provisioning-http.service';
import AppSettings from 'src/AppSettings.json';

let rowsPerPage = 500;
let newPageVariable = 1;

const UseStyles = makeStyles({
    drawer: {
        width: 250,
        flexShrink: 0,
        top: '19.5%',
        marginTop: '80px',
        marginLeft: '43%',
        position: 'absolute',
        height: '20px'
    },
    drawarModal: {
        width: '100%',
        flexShrink: 0,
        marginTop: '150px',
        alignItems: 'center',
        position: 'absolute',
        height: '260px',
        display: 'flex',
        justifyContent: 'center',
    },
    titleStyle: {
        marginLeft: '19px',
        fontSize: '16px',
        marginTop: '5px',
        fontFamily: "LynkcoTypeMedium",
        '@media (min-width: 1026px) and (max-width: 1075px)': {
            fontSize: '14px'
        },
        '@media (min-width: 1001px) and (max-width: 1025px)': {
            fontSize: '14px'
        },
        '@media (min-width: 771px) and (max-width: 1000px)': {
            fontSize: '20px'
        },
        '@media (min-width: 600px) and (max-width: 770px)': {
            fontSize: '20px'
        }
    },
    imageStyle: {
        marginTop: '25px',
        marginBottom: '30px',
        marginLeft: '-10px',
        maxHeight: '450px',
        minHeight: '380px',
        '@media (min-width: 1401px) and (max-width: 1500px)': {
            maxHeight: '370px',
            minHeight: '320px',
        },
        '@media (min-width: 1281px) and (max-width: 1400px)': {
            maxHeight: '370px',
            minHeight: '320px',
        },
        '@media (min-width: 1274px) and (max-width: 1280px)': {
            maxHeight: '370px',
            minHeight: '320px',
        },
        '@media (min-width: 1112px) and (max-width: 1273px)': {
            maxHeight: '260px',
            minHeight: '230px',
        },
        '@media (min-width: 1026px) and (max-width: 1075px)': {
            maxHeight: '310px',
            minHeight: '280px',
            marginBottom: '150px'
        },
        '@media (min-width: 1001px) and (max-width: 1025px)': {
            maxHeight: '310px',
            minHeight: '280px',
            marginBottom: '150px'
        },
        '@media (min-width: 771px) and (max-width: 1000px)': {
            marginTop: '0px',
            maxWidth: '260px',
            marginBottom: '7px'
        },
        '@media (min-width: 600px) and (max-width: 770px)': {
            marginTop: '0px',
            maxWidth: '260px',
            marginBottom: '7px'
        }
    },
    backgroundStyle: {
        marginTop: '75px',
        position: 'relative',
        '@media (min-width: 1421px) and (max-width: 1500px)': {
            marginTop: '95px',
        },
        '@media (min-width: 1401px) and (max-width: 1420px)': {
            marginTop: '125px',
        }
    },
    iconStyle: {
        marginTop: '155px',
        transform: 'scale(1.3)',
        marginBottom: '30px',
        padding: '10px',
        height: '28vh',
        width: '100%',
        alignContent: 'center'
    },
    searchfieldStyle: {
        width: '300%',
        marginTop: '20px',
        marginLeft: '5px',
        '@media (min-width: 1401px) and (max-width: 1500px)': {
            width: '315%',
        },
        '@media (min-width: 1112px) and (max-width: 1273px)': {
            width: '365%',
            marginBottom: '-30px',
            marginTop: '20px'
        },
        '@media (min-width: 1026px) and (max-width: 1075px)': {
            width: '405%',
            marginBottom: '-30px',
            marginTop: '20px'
        },
        '@media (min-width: 1001px) and (max-width: 1025px)': {
            width: '435%',
            marginBottom: '-30px',
            marginTop: '20px'
        },
        '@media (min-width: 771px) and (max-width: 1000px)': {
            width: '150%',
        },
        '@media (min-width: 600px) and (max-width: 770px)': {
            width: '170%',
        }
    },
    root: {
        overflow: 'scroll',
        overflowX: 'hidden',
        height: '500px',
        marginLeft: '-2px',

        "&::-webkit-scrollbar": {
            height: "1px",
            width: "0.1px",
            opacity: "0.3",
            backgroundColor: 'rgb(19 19 19/ 0.1%)'
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: "100px",
            backgroundColor: 'rgb(19 19 19/ 0.1%)',
            border: 'none'
        },
    },
});

const ProvisioningPage = (props) => {
    const { userPermissions, graphData } = props
    const classes = UseStyles(props);

    const [openDialog, setOpenDialog] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [open, setOpen] = useState(false);
    const [searchInput, setSearchInput] = useState(false);
    const [searchInputVIN, setSearchInputVIN] = useState(false);

    const [displaySelectvehicle, setDisplaySelectedVehicle] = useState(false);
    const [displayRole, setDisplayRole] = useState(false);

    const [iconDisplay, setIconDisplay] = useState(false);
    const [addressInput, setAddressInput] = useState('');

    const [searchVIN, setSearchVIN] = useState('');
    const [vehicleDetail, setVehicleDetail] = useState({});

    const [customerData, setCustomerData] = useState([]);
    const [sonwflakeID, setSnowflakeID] = useState('');
    const [customerMarketID, setCustomerMarketID] = useState('');
    const [vehiclePNO, setVehiclePNO] = useState('');

    const [simState, setSIMState] = useState('');

    const [openSIMCardActivationModal, setOpenSIMCardActivationModal] = useState(false);

    const [inputVIN, setInputVIN] = useState('');
    const [message, setMessage] = useState('');

    const [displayModal, setDisplayModal] = useState(false);

    const [packageID, setPackageID] = useState('');

    const [roleId, setRoleID] = useState('');
    const [spinnerConfirmButtonVehicle, setSpinnerConfirmButtonVehicle] = useState(false);
    const [spinnerConfirmButtonProvision, setSpinnerConfirmButtonProvision] = useState(false);

    const [spinner, setSpinner] = useState(false);

    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const [customerFirstName, setCustomerFirstName] = useState('');
    const [customerLastName, setCustomerLastName] = useState('');

    const customerFullName = customerFirstName + ' ' + customerLastName;

    const handleProvisioning = () => {
        let data = {}
        let vehicleFleetArray = [];
        setOpenDialog(true);
        setMessage('Please wait!');
        setDialogMessage(`${customerFullName} is being provisioned to ${inputVIN}`);

        setSpinnerConfirmButtonProvision(true);
        if (roleId !== '' && packageID !== '') {
            ProvisionVehicle(inputVIN.trim(), sonwflakeID, packageID, roleId)
                .then(async response => {
                    if (response.ok) {
                        // throw new Error("Network response was not OK");
                        data = await response.json();
                    }

                    if (response.status !== 408 || response.status !== 504) {
                        if (data.ok == true) {
                            setOpen(true);
                            setIconDisplay(true)
                            setAddressInput('');
                            setSearchVIN('');
                            setSpinnerConfirmButtonProvision(false);
                            setOpenDialog(false);
                            setMessage('');
                            setDialogMessage('');
                        } else {
                            setOpen(true);
                            setIconDisplay(false);
                            setSpinnerConfirmButtonProvision(false);
                            setOpenDialog(false);
                            setMessage('');
                            setDialogMessage('');
                        }
                    } else {
                        GetWhoIsConnectedData({ vin: searchVIN?.trim(), pageNumber: newPageVariable, pageSize: rowsPerPage }).then(data => {
                            if (data.ok == true) {
                                if (data && Array.isArray(data.items)) {
                                    data.items.map(vehicle => {
                                        vehicleFleetArray.push(vehicle)
                                    });

                                    let filteredUser = vehicleFleetArray.filter((item) => item.customerNumber == addressInput.trim());
                                    if (filteredUser.length !== 0 && filteredUser[0].unprovisionedDateUtc === null) {
                                        setOpen(true);
                                        setIconDisplay(true)
                                        setAddressInput('');
                                        setSearchVIN('');
                                        setSpinnerConfirmButtonProvision(false);
                                        setOpenDialog(false);
                                        setMessage('');
                                        setDialogMessage('');
                                    } else {
                                        setOpen(true);
                                        setIconDisplay(false);
                                        setSpinnerConfirmButtonProvision(false);
                                        setOpenDialog(false);
                                        setMessage('');
                                        setDialogMessage('');
                                    }
                                }
                            }
                        })
                    }
                })
                .catch(error => console.log(error));
        };

        if (roleId == '' && packageID !== '') {
            setOpenDialog(true);
            setMessage('Role selected!');
            setDialogMessage('Please make sure to select a role before continuing with provisioning');
            setSpinnerConfirmButtonProvision(false);
        }

        if (roleId == '' && packageID == '') {
            setOpenDialog(true);
            setMessage('Role and Package not selected!');
            setDialogMessage('Please make sure to select a role and package before continuing with provisioning');
            setSpinnerConfirmButtonProvision(false);
        }

        if (packageID == '' && roleId !== '') {
            setOpenDialog(true);
            setMessage('Package not selected!');
            setDialogMessage('Please make sure to select a package before continuing with provisioning');
            setSpinnerConfirmButtonProvision(false);
        }
    };

    const handleDialogOk = () => {
        if (displayModal) {
            setOpenDialog(false);
            setOpen(false);
            setSearchInput(false);
            setSpinner(false);
            setSpinnerConfirmButtonVehicle(false);
            setSpinnerConfirmButtonProvision(false);
            setSpinner(false);
            setShowErrorMessage(false);
        }
        if (!displayModal) {
            setOpenDialog(false);
            setOpen(false);
            setSpinner(false);
            setSpinnerConfirmButtonVehicle(false);
            setSpinnerConfirmButtonProvision(false);
            setSpinner(false);
            setShowErrorMessage(false);
            setMessage('');
            setDialogMessage('');
            setOpenSIMCardActivationModal(false);
            setSIMState('');
            setSearchInputVIN(false)
        }
    };

    const handleClose = () => {
        setOpen(false);
        setSearchInput(false);
        setDisplaySelectedVehicle(false);
        setSearchInputVIN(false);
        setDisplayRole(false);
        setSpinner(false);
        setSpinnerConfirmButtonVehicle(false);
        setSpinnerConfirmButtonProvision(false);
        setSIMState('');
        setMessage('');
        setDialogMessage('');
        setSearchVIN('');
        setInputVIN('');
    };

    const handleCancel = () => {
        setOpenDialog(false);
        setOpen(false);
        setSpinner(false);
        setSpinnerConfirmButtonVehicle(false);
        setSpinnerConfirmButtonProvision(false);
        setSpinner(false);
        setShowErrorMessage(false);
        setMessage('');
        setDialogMessage('');
        setOpenSIMCardActivationModal(false);
        setSIMState('');
        setSearchInputVIN(false)
    };

    const handleChangeCustomerNumber = () => {

        if (addressInput?.trim()?.length != 0) {
            let id = ""
            let email = ""
            let customerNumber = addressInput.trim();
            let snowflakeId = ""
            let cepArray = []

            QueryCEPCustomer(id, email, customerNumber, snowflakeId)
                .then(data => {
                    if (data.ok == true) {
                        if (data.cepCustomer && Array.isArray(data.cepCustomer)) {
                            data?.cepCustomer.map((item) => {
                                cepArray.push({
                                    firstName: item.firstName,
                                    lastName: item.lastName,
                                    email: item.email,
                                    customerType: item.customerType,
                                    market: item.market
                                });
                                if (customerNumber?.substring(0, 3) == 'B2B') {
                                    setOpenDialog(true);
                                    setMessage('Incorrect customer number');
                                    setDialogMessage(`The number consist of nine numerical digits, often starting with 100..., This number is generated during account creation by the end user. It can be found in customer's MyPages and mobile app`);
                                    setSearchInput(false);
                                    setDisplaySelectedVehicle(false);
                                } else if (item.snowflakeId?.trim() === "") {
                                    setOpenDialog(true);
                                    setMessage("Customer data missing");
                                    setDialogMessage("Customer record is missing snowflake id. Please contact CEP.");
                                    setSearchInput(false);
                                    setDisplaySelectedVehicle(false);
                                } else {
                                    setSnowflakeID(item.snowflakeId);
                                    setCustomerMarketID(item.marketIsoCode);
                                    setCustomerFirstName(item.firstName);
                                    setCustomerLastName(item.lastName);
                                    setSearchInput(true);
                                }
                            })
                        }
                        setCustomerData(cepArray);
                    } else {
                        setDisplayModal(true);
                        setOpenDialog(true);
                        setMessage('Customer ID not found!');
                        setDialogMessage('Please check if customer has an active account and try again');
                        setSearchInput(false);
                        setAddressInput('');
                        setOpen(false);
                        setDisplaySelectedVehicle(false);
                    }
                })
        }
    };

    const handleChangeVINorLIcensePlate = () => {
        if (searchVIN?.trim()?.length !== 0) {
            GetVehicleByVinOrLicensePlate(searchVIN?.trim())
                .then(data => {
                    if (data.ok == true) {
                        if (data.vehicle.vin !== null) {
                            setSearchInputVIN(true);
                            setVehicleDetail(data.vehicle);
                            setInputVIN(data?.vehicle?.vin);
                            setVehiclePNO(data?.vehicle?.pnoCode);
                            setSIMState(data?.vehicle?.simState);
                        } else {
                            setOpenDialog(true);
                            setSearchInputVIN(false);
                            setMessage('Vehicle not found!');
                            setDialogMessage('Please check that VIN or license plate is correct.');
                            setDisplayModal(false);
                        }
                    } else {
                        setOpenDialog(true);
                        setSearchInputVIN(false);
                        setMessage(data.title);
                        setDisplayModal(false);
                        setDialogMessage(data.errorMessage);
                    }
                });
        }
    };

    const handleSearchInput = (event) => {
        setAddressInput(event.target.value)
        console.log(event.target.value)
        console.log(event.target.value.length)
        if (event.target.value.length === 0) {
            setSearchInput(false);
            setDisplaySelectedVehicle(false);
            setSearchInputVIN(false);
            setDisplayRole(false);
        }
    };

    const handleSearchInputVIN = (event) => {
        setSearchVIN(event.target.value)
        console.log(event.target.value)
        console.log(event.target.value.length)
        if (event.target.value.length === 0) {
            setSearchInputVIN(false);
            setDisplayRole(false);
        }
    }

    const textFieldKeypressVIN = (e) => {
        if (e.key === 'Enter') {
            handleChangeVINorLIcensePlate();
        }
    };

    const textFieldKeypressCustomerDetails = (e) => {
        if (e.key === 'Enter') {
            handleChangeCustomerNumber();
        }
    };


    const handleConfirmVehicle = () => {
        setSpinnerConfirmButtonVehicle(true);
        let vin = inputVIN.trim()
        setSpinner(true);
        let users = [];
        let customerNumber = [];

        if (AppSettings.config["environment"] === "production") {
            if (simState == 'ACTIVE') {
                GetUsersProvisionedToVehicle(vin).then(data => {
                    let filterdUsers = data.usersProvisionedToVehicle.filter((item) => item.userId === sonwflakeID)[0];
                    let isUserProvisioned = data?.usersProvisionedToVehicle?.includes(filterdUsers);

                    if (isUserProvisioned && data.isTestDriveVehicle == false) {
                        // setProvisionedUsers(users);
                        setSpinnerConfirmButtonVehicle(false);
                        setDisplayModal(false);
                        setSearchInputVIN(false);
                        setDisplayRole(false);
                        setOpenDialog(true);
                        setMessage('');
                        setDialogMessage(`${filterdUsers.customerName} is already provisioned to this vehicle`);
                    } else {
                        if ((data.ok == true && data.usersProvisionedToVehicle.length === 0) || (data.ok === true && data.isTestDriveVehicle === true && data.otdFleetType === 'Yes')) {
                            setDisplayRole(true);
                            setSpinner(false);
                            setSpinnerConfirmButtonVehicle(false);
                            setSpinner(false);
                        } else {
                            data.usersProvisionedToVehicle.map((item) => {
                                users.push(item.customerName);
                                customerNumber.push(item.customerNumber)
                            })
                            setSpinnerConfirmButtonVehicle(false);
                            setDisplayModal(false);
                            setSearchInputVIN(false);
                            setDisplayRole(false);
                            setOpenDialog(true);
                            setMessage('Vehicle has an assigned driver!');
                            setDialogMessage(`Please un-provision ${users} with customer number ${customerNumber}.`);
                        }
                    }
                })
            } else {
                setOpen(true);
                setOpenSIMCardActivationModal(true);
            }
        } else {
            GetUsersProvisionedToVehicle(vin).then(data => {
                let filterdUsers = data?.usersProvisionedToVehicle?.filter((item) => item.userId === sonwflakeID)[0];
                let isUserProvisioned = data?.usersProvisionedToVehicle?.includes(filterdUsers);

                if (isUserProvisioned && data.isTestDriveVehicle === false) {
                    setSpinnerConfirmButtonVehicle(false);
                    setDisplayModal(false);
                    setSearchInputVIN(false);
                    setDisplayRole(false);
                    setOpenDialog(true);
                    setMessage('');
                    setDialogMessage(`${filterdUsers.customerName} is already provisioned to this vehicle`);
                } else {
                    if ((data.ok == true && data.usersProvisionedToVehicle.length == 0) || (data.ok === true && data.isTestDriveVehicle === true && data.otdFleetType === 'Yes')) {
                        setDisplayRole(true);
                        setSpinner(false);
                        setSpinnerConfirmButtonVehicle(false);
                        setSpinner(false);
                    } else {
                        data.usersProvisionedToVehicle.map((item) => {
                            users.push(item.customerName);
                            customerNumber.push(item.customerNumber)
                        })
                        setSpinnerConfirmButtonVehicle(false);
                        setDisplayModal(false);
                        setSearchInputVIN(false);
                        setDisplayRole(false);
                        setOpenDialog(true);
                        setMessage('Vehicle has an assigned driver!');
                        setDialogMessage(`Please un-provision ${users} with customer number ${customerNumber}.`);
                    }
                }
            })
        }
    };

    const handleActivateSIM = () => {
        setOpen(false);
        setOpenSIMCardActivationModal(false);
        ActivateVehicleSim(inputVIN.trim(), graphData.mail).then(data => {
            if (data.ok == true && data.completed !== true) {
                setOpenDialog(true);
                setMessage('Your request is being processed');
                setDialogMessage('This may take a few minutes, you will be notified when ready.');
            }
            if (data.ok == true && data.completed == true) {
                setDisplayRole(true);
                setSpinner(false);
                setSpinnerConfirmButtonVehicle(false);
                setSpinner(false);
            }
            else if (data.completed == false) {
                setOpenDialog(true);
                setMessage(data.title);
                setDialogMessage(data.errorMessage);
            }
        })
    };

    const handleChangePackageDropdown = (event) => {
        const { value } = event.target;

        setPackageID(value);
        if (value == 0) {
            setPackageID('');
        }
    };

    const handleChangeRoleDropdown = (event) => {
        const { value } = event.target;
        setRoleID(value);
        if (value == 0) {
            setRoleID('');
        }
    };

    const matchesPermission = userPermissions.includes(permission.Connectivity_Reader);

    return (
        <Container>
            <div className={classes.backgroundStyle}>
                <VehiclePageDialog
                    dialogue='true'
                    open={openDialog}
                    message={dialogMessage}
                    handleDialogOk={handleDialogOk}
                    title={showErrorMessage ? '' : message}
                    dialogHeight='true'
                    spinnerConfirmButtonProvision={spinnerConfirmButtonProvision}
                />

                <Modal
                    className={openSIMCardActivationModal ? classes.drawarModal : classes.drawer}
                    variant="persistent"
                    anchor="right"
                    open={open}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    onClose={handleClose}
                    BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.8)' } }}
                >
                    <>
                        {
                            openSIMCardActivationModal ?
                                <div style={{
                                    border: 'none', height: '250px', alignItems: 'center', justifyContent: 'center', position: 'absolute', display: 'flex', padding: '20px', width: '32%', backgroundColor: '#181818', borderRadius: '15px', marginTop: '0px'
                                }}>
                                    <div style={{ textAlign: 'center', fontSize: '24px', fontFamily: "LynkcoTypeRegular", marginTop: '-160px', color: 'white', alignItems: 'center', justifyContent: 'center', position: 'absolute', display: 'flex' }} variant="h4" id="tableTitle" component="div">
                                        SIM card is not active
                                    </div>
                                    <div style={{ textAlign: 'center', fontSize: '20px', fontFamily: "LynkcoTypeRegular", marginTop: '-70px', color: 'white', alignItems: 'center', justifyContent: 'center', position: 'absolute', display: 'flex', textWrap: 'nowrap' }} variant="h4" id="tableTitle" component="div">
                                        SIM needs to be active to continue with provisioning.
                                    </div>

                                    <div style={{ textAlign: 'center', fontSize: '20px', fontFamily: "LynkcoTypeRegular", marginTop: '-20px', color: 'white', alignItems: 'center', justifyContent: 'center', position: 'absolute', display: 'flex', textWrap: 'nowrap' }} variant="h4" id="tableTitle" component="div">
                                        Do you want to activate SIM?
                                    </div>

                                    <Row className='downloadstyle' style={{ marginTop: '70px' }}>
                                        <Col lg="6" md="6">
                                            <SearchButton
                                                placeholder={'Proceed'}
                                                handleSearch={() => handleActivateSIM()}
                                                height='true'
                                                loader='true'
                                            />
                                        </Col>

                                        <Col lg="6" md="6">
                                            <SearchButton
                                                placeholder={'Cancel'}
                                                handleSearch={() => handleCancel()}
                                                height='true'
                                                rsa='true'
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                :
                                <div>
                                    <CardImg
                                        alt="Card image cap"
                                        src={iconDisplay ? okIcon : noIcon}
                                        className={classes.iconStyle}
                                    />
                                </div>
                        }
                    </>
                </Modal>
                {
                    matchesPermission ?
                        <>
                            <Row className='headerStyleProvisioning'>
                                <Col lg="8" md="8" sm="8" xs="8" ></Col>
                                <Col lg="4" md="4" sm="4" xs="4" ></Col>
                            </Row>

                            <Row className="rowstyleProvisioning">
                                {
                                    <>
                                        <Col lg="4" md="12" >
                                            <Card id="CardBodyCardAllowance3" >
                                                <CardBody>
                                                    <Row>
                                                        <Col lg="12" md="12" >
                                                            <Typography className={classes.titleStyle} variant="h4" id="tableTitle" component="div">
                                                                Select Driver
                                                            </Typography>
                                                            <div className={classes.searchfieldStyle} >
                                                                <SearchBarProvisioning
                                                                    priceBucketActiveTab='true'
                                                                    placeholder={'Customer Number'}
                                                                    handleSearch={handleChangeCustomerNumber}
                                                                    handleSearchInput={handleSearchInput}
                                                                    onKeyPress={textFieldKeypressCustomerDetails}
                                                                    value={addressInput}
                                                                />
                                                            </div>
                                                        </Col>

                                                        <Row>
                                                            <Col lg="12" md="12" >

                                                                {searchInput &&
                                                                    <>
                                                                        {customerData != undefined && customerData.map((value, key) => {
                                                                            return (
                                                                                <div key={key}>
                                                                                    <div className={classes.root}>
                                                                                        <Col lg="12" md="12" >
                                                                                            <div style={{ marginLeft: '10px' }}>
                                                                                                <div className="cstomercardProvisioningHeight" style={{ marginTop: '27px' }}>
                                                                                                    <span className="fontsizeProvisioning" style={{ fontFamily: 'LynkcoTypeMedium' }}>First Name</span><br />
                                                                                                    <span className="fontsizeProvisioning" style={{ fontFamily: 'LynkcoTypeRegular' }}>{value.firstName}</span> <br />
                                                                                                </div>

                                                                                                <div className="cstomercardProvisioningHeight">
                                                                                                    <span className="fontsizeProvisioning" style={{ fontFamily: 'LynkcoTypeMedium' }}>Last Name</span><br />
                                                                                                    <span className="fontsizeProvisioning" style={{ fontFamily: 'LynkcoTypeRegular' }}>{value.lastName}</span> <br />
                                                                                                </div>

                                                                                                <div className="cstomercardProvisioningHeight">
                                                                                                    <span className="fontsizeProvisioning" style={{ fontFamily: 'LynkcoTypeMedium' }}>Email</span><br />
                                                                                                    <span className="fontsizeProvisioning" style={{ fontFamily: 'LynkcoTypeRegular' }}>{value.email}</span> <br />
                                                                                                </div>

                                                                                                <div className="cstomercardProvisioningHeight">
                                                                                                    <span className="fontsizeProvisioning" style={{ fontFamily: 'LynkcoTypeMedium' }}>Customer</span><br />
                                                                                                    <span className="fontsizeProvisioning" style={{ fontFamily: 'LynkcoTypeRegular' }}>{value.customerType}</span> <br />
                                                                                                </div>

                                                                                                <div className="cstomercardProvisioningHeight">
                                                                                                    <span className="fontsizeProvisioning" style={{ fontFamily: 'LynkcoTypeMedium' }}>Market</span><br />
                                                                                                    <span className="fontsizeProvisioning" style={{ fontFamily: 'LynkcoTypeRegular' }}>{value.market}</span> <br />
                                                                                                </div>
                                                                                            </div>
                                                                                        </Col>
                                                                                    </div>
                                                                                </div>
                                                                            )

                                                                        })}
                                                                    </>
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </Row>
                                                    <Row style={{ float: 'right', paddingRight: '15px', marginTop: '-70px' }}>
                                                        <tr>
                                                            <td>
                                                                {searchInput && customerData != undefined && !displaySelectvehicle &&
                                                                    <SearchButton
                                                                        placeholder={'Confirm'}
                                                                        handleSearch={() => {
                                                                            setDisplaySelectedVehicle(true);
                                                                            setSearchVIN('');
                                                                        }
                                                                        }
                                                                    />
                                                                }
                                                            </td>
                                                        </tr>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>

                                        <Col lg="4" md="12" >
                                            {displaySelectvehicle &&
                                                <Card id="CardBodyCardAllowance3">
                                                    <CardBody>
                                                        <Row>
                                                            <Col lg="12" md="12" >
                                                                <Typography className={classes.titleStyle} variant="h4" id="tableTitle" component="div">
                                                                    Select Vehicle
                                                                </Typography>
                                                                <div className={classes.searchfieldStyle} >
                                                                    <SearchBarProvisioning
                                                                        priceBucketActiveTab='true'
                                                                        handleSearch={handleChangeVINorLIcensePlate}
                                                                        handleSearchInput={handleSearchInputVIN}
                                                                        onKeyPress={textFieldKeypressVIN}
                                                                        placeholder={'VIN or License Plate'}
                                                                        value={searchVIN}
                                                                    />
                                                                </div>
                                                            </Col>

                                                            <Row>
                                                                {searchInputVIN &&
                                                                    <>
                                                                        {
                                                                            vehicleDetail != undefined &&
                                                                            <div>
                                                                                <Row style={{ marginLeft: '25px' }}>
                                                                                    <Col lg="7" md="12" >
                                                                                        <CardImg
                                                                                            alt="Card image cap"
                                                                                            src={vehicleDetail.color == 'Blue' ? profilebgBlue : profilebgBlack}
                                                                                            className={classes.imageStyle}
                                                                                        />
                                                                                    </Col>

                                                                                    <Col lg="5" md="12" >
                                                                                        <div className="vehiclecardrowStyle">
                                                                                            <div className="vehiclecardProvisioningHeight" style={{ marginTop: '39px' }}>
                                                                                                <span className="fontsizeProvisioning" style={{ fontFamily: 'LynkcoTypeMedium' }}>Model</span><br />
                                                                                                <span className="fontsizeProvisioning" style={{ fontFamily: 'LynkcoTypeRegular' }} >{vehicleDetail.model === '01' ? 'Lynk & Co 01' : ''}</span> <br /><br />
                                                                                            </div>

                                                                                            <div className="vehiclecardProvisioningHeight">
                                                                                                <span className="fontsizeProvisioning" style={{ fontFamily: 'LynkcoTypeMedium' }}>Color</span><br />
                                                                                                <span className="fontsizeProvisioning" style={{ fontFamily: 'LynkcoTypeRegular' }}>{vehicleDetail.color === 'Blue' ? 'Blue' : 'Black'}</span> <br /><br />
                                                                                            </div>

                                                                                            <div className="vehiclecardProvisioningHeight">
                                                                                                <span className="fontsizeProvisioning" style={{ fontFamily: 'LynkcoTypeMedium' }}>Engine Type</span><br />
                                                                                                <span className="fontsizeProvisioning" style={{ fontFamily: 'LynkcoTypeRegular' }}>{vehicleDetail.engineType}</span> <br /><br />
                                                                                            </div>

                                                                                            <div className="vehiclecardProvisioningHeight" style={{ marginRight: '-10px' }}>
                                                                                                <span className="fontsizeProvisioning" style={{ fontFamily: 'LynkcoTypeMedium' }}>Model Year</span><br />
                                                                                                <span className="fontsizeProvisioning" style={{ fontFamily: 'LynkcoTypeRegular' }}>{vehicleDetail.modelYear}</span> <br /><br />
                                                                                            </div>

                                                                                            <div className="vehiclecardProvisioningHeight" >
                                                                                                <span className="fontsizeProvisioning" style={{ fontFamily: 'LynkcoTypeMedium' }}>Towbar</span><br />
                                                                                                <span className="fontsizeProvisioning" style={{ fontFamily: 'LynkcoTypeRegular' }}>{vehicleDetail.hasTowbar === false ? 'No' : 'Yes'}</span> <br /><br />
                                                                                            </div>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                        }
                                                                    </>}
                                                            </Row>
                                                        </Row>
                                                        <Row className="vehiclebuttonheightProvisioing">
                                                            <tr>
                                                                <td >
                                                                    {
                                                                        spinnerConfirmButtonVehicle ?
                                                                            <div className="loaderstyle">
                                                                                <FadeLoader color="#1EF1C6" radius={1} width={3} height={8} margin={-5} />
                                                                            </div>
                                                                            :
                                                                            <>
                                                                                {displaySelectvehicle && searchInputVIN && vehicleDetail !== undefined && !displayRole &&

                                                                                    <SearchButton
                                                                                        placeholder={'Confirm'}
                                                                                        handleSearch={() => handleConfirmVehicle()}
                                                                                    />
                                                                                }
                                                                            </>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            }
                                        </Col>

                                        <Col lg="4" md="12" >
                                            {
                                                spinner ?
                                                    <div className="spinnerloader">
                                                        <div style={{ fontSize: '25px', color: '#fff', textAlign: 'center', fontFamily: 'LynkcoTypeRegular', whiteSpace: 'nowrap' }}>
                                                            <PuffLoader color="#1EF1C6" size={200} />
                                                        </div>
                                                    </div>
                                                    :
                                                    <>
                                                        {
                                                            spinner ?
                                                                <div className="spinnerloader">
                                                                    <div style={{ fontSize: '25px', color: '#fff', textAlign: 'center', fontFamily: 'LynkcoTypeRegular', whiteSpace: 'nowrap' }}>
                                                                        <PuffLoader color="#1EF1C6" size={200} />
                                                                    </div>
                                                                </div>
                                                                :
                                                                <>
                                                                    {displayRole &&
                                                                        <Card id="CardBodyCardAllowance3">
                                                                            <CardBody>
                                                                                <Row>
                                                                                    <Col lg="12" md="12" >
                                                                                        <Typography className={classes.titleStyle} variant="h4" id="tableTitle" component="div">
                                                                                            Select Role and Package
                                                                                        </Typography>

                                                                                        <table className="tablestyleProvisioning">
                                                                                            <tr>
                                                                                                <td style={{ float: 'left', fontFamily: 'LynkcoTypeRegular', color: '#BFBFBF', marginLeft: '20px' }}>
                                                                                                    <div style={{ minWidth: '250px' }}>
                                                                                                        <RoleSelect value={roleId.id} onChange={handleChangeRoleDropdown} />
                                                                                                    </div>
                                                                                                    <br />
                                                                                                </td>
                                                                                            </tr> <br /> <br />
                                                                                            <tr>
                                                                                                <td style={{ float: 'left', fontFamily: 'LynkcoTypeRegular', color: '#BFBFBF', marginLeft: '20px' }}>
                                                                                                    <div style={{ minWidth: '250px' }}>
                                                                                                        <PackageSelect
                                                                                                            value={packageID.id}
                                                                                                            onChange={handleChangePackageDropdown}
                                                                                                            marketCode={customerMarketID}
                                                                                                            pnoCode={vehiclePNO}
                                                                                                            setOpenDialog={setOpenDialog}
                                                                                                            setDialogMessage={setDialogMessage}
                                                                                                            setShowErrorMessage={setShowErrorMessage}
                                                                                                        />
                                                                                                    </div>
                                                                                                    <br />
                                                                                                </td>
                                                                                            </tr>

                                                                                            <tr>
                                                                                                <td className="confirmbuttonheightProvisioing" >
                                                                                                    {
                                                                                                        spinnerConfirmButtonProvision ?
                                                                                                            <div className="loaderstyle">
                                                                                                                <FadeLoader color="#1EF1C6" radius={1} width={3} height={8} margin={-5} />
                                                                                                            </div>
                                                                                                            :
                                                                                                            <SearchButton
                                                                                                                placeholder={'Provision'}
                                                                                                                handleSearch={handleProvisioning}
                                                                                                            />
                                                                                                    }
                                                                                                </td>
                                                                                            </tr>
                                                                                        </table>
                                                                                    </Col>
                                                                                </Row>
                                                                            </CardBody>
                                                                        </Card>
                                                                    }
                                                                </>
                                                        }
                                                    </>
                                            }
                                        </Col>
                                    </>
                                }
                            </Row>
                        </>
                        :
                        <DefaultPage />
                }
            </div>
        </Container>
    );
};

const mapStateToProps = state => ({
    userOrg: selectUserOrganization(state),
    userMarketIds: selectUserMarketIds(state),
    userPermissions: selectUserPermissions(state),
    graphData: selectUserGraphData(state)
});

export default connect(mapStateToProps, null)(ProvisioningPage);